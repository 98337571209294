import { Link } from 'react-router-dom';
import { Facebook, Twitter, Instagram, Youtube, Mail, Heart, Linkedin } from 'lucide-react';
import Logo from './Logo';
import ScrollLink from './ScrollLink';

export default function Footer() {
  const navigation = {
    product: [
      { name: 'Benefits', href: '/benefits' },
      { name: 'How it Works', href: '/how-it-works' },
      { name: 'Pricing', href: '/pricing' },
      { name: 'About', href: '/about' },
    ],
    support: [
      { name: 'Contact', href: '/contact' },
      { name: 'Documentation', href: '#' },
      { name: 'FAQs', href: '#' },
      { name: 'Community', href: '#' },
    ],
    legal: [
      { name: 'Privacy Policy', href: '#' },
      { name: 'Terms of Service', href: '#' },
      { name: 'Cookie Policy', href: '#' },
      { name: 'Data Protection', href: '#' },
    ],
    social: [
      { name: 'Facebook', icon: Facebook, href: 'https://www.facebook.com/techlearn.academy' },
      { name: 'LinkedIn', icon: Linkedin, href: 'https://www.linkedin.com/company/techlearnacademy' },
      { name: 'Twitter', icon: Twitter, href: '#' },
      { name: 'Instagram', icon: Instagram, href: '#' },
      { name: 'YouTube', icon: Youtube, href: '#' },
    ],
  };

  return (
    <footer className="bg-gray-900 border-t border-gray-800">
      <div className="container mx-auto px-4 py-12">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-8">
          {/* Logo and Description */}
          <div className="lg:col-span-2">
            <ScrollLink to="/" className="inline-block">
              <Logo />
            </ScrollLink>
            <p className="mt-4 text-gray-400 max-w-md">
              Transform your tech journey with our AI-powered learning platform. 
              Master new skills with personalized guidance and real-time feedback.
            </p>
            <div className="mt-6 flex items-center space-x-4">
              {navigation.social.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-400 hover:text-purple-400 transition-colors"
                >
                  <item.icon className="w-5 h-5" />
                </a>
              ))}
            </div>
          </div>

          {/* Navigation Links */}
          <div>
            <h3 className="text-white font-semibold mb-4">Product</h3>
            <ul className="space-y-3">
              {navigation.product.map((item) => (
                <li key={item.name}>
                  <ScrollLink
                    to={item.href}
                    className="text-gray-400 hover:text-purple-400 transition-colors"
                  >
                    {item.name}
                  </ScrollLink>
                </li>
              ))}
            </ul>
          </div>

          <div>
            <h3 className="text-white font-semibold mb-4">Support</h3>
            <ul className="space-y-3">
              {navigation.support.map((item) => (
                <li key={item.name}>
                  <ScrollLink
                    to={item.href}
                    className="text-gray-400 hover:text-purple-400 transition-colors"
                  >
                    {item.name}
                  </ScrollLink>
                </li>
              ))}
            </ul>
          </div>

          <div>
            <h3 className="text-white font-semibold mb-4">Legal</h3>
            <ul className="space-y-3">
              {navigation.legal.map((item) => (
                <li key={item.name}>
                  <a
                    href={item.href}
                    className="text-gray-400 hover:text-purple-400 transition-colors"
                  >
                    {item.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* Contact Information */}
        <div className="border-t border-gray-800 mt-12 pt-8">
          <div className="flex items-center justify-center space-x-3 text-gray-400">
            <Mail className="w-5 h-5" />
            <span>support@tech-learn.academy</span>
          </div>
        </div>

        {/* Copyright */}
        <div className="border-t border-gray-800 mt-8 pt-8">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <p className="text-gray-400 text-sm">
              © {new Date().getFullYear()} Tech Learn Academy. All rights reserved.
            </p>
            <p className="text-gray-400 text-sm mt-4 md:mt-0">
              Made with <Heart className="w-4 h-4 inline text-red-500" /> by Tech Learn Academy Team
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}