import { X, AlertCircle } from 'lucide-react';
import { Course } from '../../types/course';

interface EnrollmentModalProps {
  course: Course;
  tokenBalance: number;
  onConfirm: () => void;
  onClose: () => void;
}

export default function EnrollmentModal({ 
  course, 
  tokenBalance, 
  onConfirm, 
  onClose 
}: EnrollmentModalProps) {
  const formatTokens = (tokens: number) => {
    return new Intl.NumberFormat().format(tokens);
  };

  const canEnroll = tokenBalance >= course.tokenCost;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-4">
      <div 
        className="absolute inset-0 bg-black/50 backdrop-blur-sm"
        onClick={onClose}
      />
      
      <div className="relative bg-gradient-to-br from-purple-900 to-gray-900 rounded-2xl p-8 max-w-md w-full shadow-2xl animate-slideIn">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-white transition-colors"
        >
          <X className="w-6 h-6" />
        </button>

        <h3 className="text-2xl font-bold text-white mb-4">
          Enroll in Course
        </h3>

        <div className="space-y-4 mb-6">
          <p className="text-purple-200">
            You're about to enroll in <strong>{course.title}</strong>
          </p>
          
          <div className="bg-gray-800/50 rounded-lg p-4 space-y-2">
            <div className="flex justify-between text-gray-300">
              <span>Course Cost:</span>
              <span>{formatTokens(course.tokenCost)} tokens</span>
            </div>
            <div className="flex justify-between text-gray-300">
              <span>Your Balance:</span>
              <span>{formatTokens(tokenBalance)} tokens</span>
            </div>
            <div className="border-t border-gray-700 pt-2">
              <div className="flex justify-between font-semibold">
                <span>Remaining Balance:</span>
                <span className={tokenBalance - course.tokenCost < 0 ? 'text-red-400' : 'text-green-400'}>
                  {formatTokens(tokenBalance - course.tokenCost)} tokens
                </span>
              </div>
            </div>
          </div>

          {!canEnroll && (
            <div className="flex items-start gap-2 text-red-400 bg-red-400/10 rounded-lg p-4">
              <AlertCircle className="w-5 h-5 flex-shrink-0 mt-0.5" />
              <p>
                Insufficient tokens. You need {formatTokens(course.tokenCost - tokenBalance)} more tokens 
                to enroll in this course.
              </p>
            </div>
          )}
        </div>

        <div className="flex gap-4">
          <button
            onClick={onClose}
            className="flex-1 py-3 px-6 bg-gray-700 hover:bg-gray-600 text-white font-semibold rounded-lg transition-colors"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            disabled={!canEnroll}
            className="flex-1 py-3 px-6 bg-purple-600 hover:bg-purple-700 disabled:bg-gray-600 
              disabled:cursor-not-allowed text-white font-semibold rounded-lg transition-colors"
          >
            Confirm Enrollment
          </button>
        </div>
      </div>
    </div>
  );
}