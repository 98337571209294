import { Smartphone, ShoppingBag, Store } from 'lucide-react';
import ComingSoonPopup from './common/ComingSoonPopup';
import { useComingSoon } from '../hooks/useComingSoon';

export default function MobileApps() {
  const { isPopupOpen, showComingSoon, hideComingSoon } = useComingSoon();

  return (
    <>
      <section className="py-20 bg-gray-900 relative overflow-hidden">
        <div className="container mx-auto px-4">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <div className="relative z-10">
              <div className="flex items-center gap-2 mb-4">
                <Smartphone className="text-purple-400 w-6 h-6" />
                <span className="text-purple-400">Mobile Learning</span>
              </div>
              <h2 className="text-4xl md:text-5xl font-bold text-white mb-6">
                Learn on Any Device
              </h2>
              <p className="text-gray-300 text-lg leading-relaxed mb-8">
                Take your learning journey anywhere with our mobile apps. Access courses, 
                practice exercises, and get AI assistance on the go. Available for both 
                iOS and Android devices.
              </p>
              
              <div className="flex flex-col sm:flex-row gap-4">
                <button 
                  onClick={showComingSoon}
                  className="group relative inline-flex items-center justify-center bg-black hover:bg-gray-900 text-white font-semibold px-8 py-4 rounded-lg transition-all overflow-hidden"
                >
                  {/* Background animation */}
                  <div className="absolute inset-0 w-3 bg-gradient-to-r from-purple-400 to-pink-400 transition-all duration-[400ms] ease-out group-hover:w-full opacity-40"></div>
                  {/* Icon and text container */}
                  <div className="relative flex items-center gap-3">
                    <Store className="w-6 h-6 transition-transform group-hover:scale-110" />
                    <div className="flex flex-col items-start">
                      <span className="text-xs opacity-80">Download on the</span>
                      <span className="text-sm font-bold">App Store</span>
                    </div>
                  </div>
                </button>

                <button 
                  onClick={showComingSoon}
                  className="group relative inline-flex items-center justify-center bg-black hover:bg-gray-900 text-white font-semibold px-8 py-4 rounded-lg transition-all overflow-hidden"
                >
                  {/* Background animation */}
                  <div className="absolute inset-0 w-3 bg-gradient-to-r from-green-400 to-emerald-400 transition-all duration-[400ms] ease-out group-hover:w-full opacity-40"></div>
                  {/* Icon and text container */}
                  <div className="relative flex items-center gap-3">
                    <ShoppingBag className="w-6 h-6 transition-transform group-hover:scale-110" />
                    <div className="flex flex-col items-start">
                      <span className="text-xs opacity-80">GET IT ON</span>
                      <span className="text-sm font-bold">Google Play</span>
                    </div>
                  </div>
                </button>
              </div>
            </div>
            
            <div className="relative">
              <div className="relative z-10 bg-gradient-to-br from-purple-900/20 to-purple-600/20 rounded-lg p-2 shadow-2xl">
                <img
                  src="https://images.unsplash.com/photo-1512941937669-90a1b58e7e9c?auto=format&fit=crop&q=80"
                  alt="Mobile Learning Apps"
                  className="rounded-lg w-full shadow-lg"
                />
                <div className="absolute -top-4 -right-4 w-20 h-20">
                  <div className="absolute inset-0 bg-purple-500 opacity-20 rounded-full animate-ping"></div>
                  <div className="absolute inset-0 bg-purple-500 opacity-40 rounded-full"></div>
                </div>
              </div>
              
              {/* Decorative elements */}
              <div className="absolute -top-10 -right-10 w-40 h-40 bg-purple-600/20 rounded-full blur-3xl"></div>
              <div className="absolute -bottom-10 -left-10 w-40 h-40 bg-purple-600/20 rounded-full blur-3xl"></div>
            </div>
          </div>
        </div>
      </section>

      <ComingSoonPopup 
        isOpen={isPopupOpen}
        onClose={hideComingSoon}
      />
    </>
  );
}