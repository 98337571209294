import { compare, hash } from 'bcryptjs';
import * as jose from 'jose';
import { z } from 'zod';
import { createUser, getUserByEmail } from './db';
import { initializeTokens } from './tokenStore';

const JWT_SECRET = new TextEncoder().encode(
  import.meta.env.VITE_JWT_SECRET || 'your-super-secret-key-change-this-in-production'
);

export const loginSchema = z.object({
  email: z.string().email(),
  password: z.string().min(6),
});

export const registerSchema = loginSchema.extend({
  name: z.string().min(2),
});

export async function register(data: z.infer<typeof registerSchema>) {
  const { email, password, name } = registerSchema.parse(data);
  
  try {
    const hashedPassword = await hash(password, 10);
    const user = await createUser({ email, password: hashedPassword, name });

    // Initialize tokens for new user
    await initializeTokens(user.id);

    const token = await new jose.SignJWT({ userId: user.id })
      .setProtectedHeader({ alg: 'HS256' })
      .setExpirationTime('24h')
      .sign(JWT_SECRET);

    return { 
      token, 
      user: { 
        id: user.id, 
        email: user.email, 
        name: user.name 
      } 
    };
  } catch (error) {
    if (error instanceof Error) {
      throw error;
    }
    throw new Error('Failed to register user');
  }
}

export async function login(data: z.infer<typeof loginSchema>) {
  const { email, password } = loginSchema.parse(data);
  
  const user = await getUserByEmail(email);
  if (!user) {
    throw new Error('Invalid credentials');
  }

  const valid = await compare(password, user.password);
  if (!valid) {
    throw new Error('Invalid credentials');
  }

  // Initialize tokens if not already initialized
  await initializeTokens(user.id);

  const token = await new jose.SignJWT({ userId: user.id })
    .setProtectedHeader({ alg: 'HS256' })
    .setExpirationTime('24h')
    .sign(JWT_SECRET);

  return { 
    token, 
    user: { 
      id: user.id, 
      email: user.email, 
      name: user.name 
    } 
  };
}

export async function verifyToken(token: string) {
  try {
    const { payload } = await jose.jwtVerify(token, JWT_SECRET);
    return payload;
  } catch {
    return null;
  }
}