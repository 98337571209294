import { Sparkles, Star } from 'lucide-react';
import StarField from './header/StarField';
import ScrollIndicator from './header/ScrollIndicator';
import ComingSoonPopup from './common/ComingSoonPopup';
import { useComingSoon } from '../hooks/useComingSoon';

export default function Hero() {
  const { isPopupOpen, showComingSoon, hideComingSoon } = useComingSoon();

  return (
    <div className="relative min-h-screen flex flex-col items-center justify-center overflow-hidden">
      <StarField />
      
      <div className="relative z-10 text-center px-4 space-y-8">
        {/* Tagline */}
        <div className="inline-block">
          <div className="bg-white/10 backdrop-blur-sm px-6 py-2 rounded-full border border-white/20">
            <div className="flex items-center gap-2">
              <Sparkles className="w-4 h-4 text-purple-300" />
              <span className="text-purple-100">The Future of Education is Here</span>
            </div>
          </div>
        </div>

        {/* Main Headline */}
        <div className="space-y-4">
          <h1 className="text-6xl md:text-8xl font-bold">
            <span className="text-white">AI Powered</span>
            <br />
            <span className="text-gray-300">Learning</span>
          </h1>
          
          <p className="text-xl text-purple-200 max-w-2xl mx-auto">
            Transform your tech journey with our AI-powered learning platform
          </p>
        </div>

        {/* CTA Button */}
        <button 
          onClick={showComingSoon}
          className="group relative px-8 py-4 rounded-full overflow-hidden transform hover:scale-105 transition-all duration-300"
        >
          <div className="absolute inset-0 bg-gradient-to-r from-pink-500 to-purple-600"></div>
          <div className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-300 bg-gradient-to-r from-purple-600 to-pink-500"></div>
          <div className="relative flex items-center gap-2">
            <Star className="w-5 h-5 text-white" />
            <span className="text-lg font-semibold text-white">Try it now</span>
          </div>
        </button>
      </div>

      <ScrollIndicator />
      
      <ComingSoonPopup 
        isOpen={isPopupOpen}
        onClose={hideComingSoon}
      />
    </div>
  );
}