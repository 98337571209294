import { useState, useEffect } from 'react';
import { User, Award, Coins } from 'lucide-react';
import { getTokenBalance, claimTokens, hasClaimedTokens } from '../../lib/tokenStore';
import TokenClaimPopup from '../common/TokenClaimPopup';

interface ProfileProps {
  user: {
    name: string;
    email: string;
  } | null;
}

export default function Profile({ user }: ProfileProps) {
  const [tokenBalance, setTokenBalance] = useState<number>(0);
  const [claiming, setClaiming] = useState(false);
  const [hasTokens, setHasTokens] = useState(false);
  const [showClaimPopup, setShowClaimPopup] = useState(false);

  useEffect(() => {
    const loadTokenData = async () => {
      if (!user) return;
      const userId = parseInt(localStorage.getItem('userId') || '0');
      const [balance, claimed] = await Promise.all([
        getTokenBalance(userId),
        hasClaimedTokens(userId)
      ]);
      setTokenBalance(balance);
      setHasTokens(claimed);
    };

    loadTokenData();
  }, [user]);

  const handleClaimTokens = async () => {
    if (!user) return;
    
    try {
      setClaiming(true);
      const userId = parseInt(localStorage.getItem('userId') || '0');
      const success = await claimTokens(userId, 2000000); // Claim 2M tokens
      
      if (success) {
        const newBalance = await getTokenBalance(userId);
        setTokenBalance(newBalance);
        setHasTokens(true);
        setShowClaimPopup(true);
      }
    } catch (error) {
      console.error('Failed to claim tokens:', error);
    } finally {
      setClaiming(false);
    }
  };

  if (!user) {
    return (
      <div className="flex items-center justify-center h-full">
        <div className="text-center">
          <div className="w-16 h-16 bg-gray-700 rounded-full animate-pulse mx-auto mb-4"></div>
          <p className="text-gray-400">Loading profile...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-8">
      <div className="flex items-center justify-between">
        <h2 className="text-2xl font-bold text-white">Profile</h2>
        <button className="px-4 py-2 bg-purple-600 hover:bg-purple-700 text-white rounded-lg transition-colors">
          Edit Profile
        </button>
      </div>

      <div className="bg-gray-700/50 rounded-lg p-6">
        <div className="flex items-center gap-6 mb-8">
          <div className="w-24 h-24 bg-gray-600 rounded-full flex items-center justify-center">
            <User className="w-12 h-12 text-gray-400" />
          </div>
          <div>
            <h3 className="text-xl font-semibold text-white mb-2">{user.name}</h3>
            <p className="text-gray-400">{user.email}</p>
          </div>
        </div>

        {/* Token Balance Section */}
        <div className="bg-gray-800/50 rounded-lg p-6 mb-8">
          <div className="flex items-center justify-between mb-4">
            <div className="flex items-center gap-2">
              <Coins className="w-6 h-6 text-purple-400" />
              <h4 className="text-lg font-medium text-white">Token Balance</h4>
            </div>
            <span className="text-2xl font-bold text-purple-400">
              {new Intl.NumberFormat().format(tokenBalance)}
            </span>
          </div>
          
          {!hasTokens && (
            <button
              onClick={handleClaimTokens}
              disabled={claiming}
              className="w-full py-3 px-6 bg-purple-600 hover:bg-purple-700 disabled:bg-gray-600 
                disabled:cursor-not-allowed text-white font-semibold rounded-lg transition-colors 
                flex items-center justify-center gap-2"
            >
              <Coins className="w-5 h-5" />
              {claiming ? 'Claiming...' : 'Claim 2M Tokens'}
            </button>
          )}
        </div>

        <div className="grid md:grid-cols-2 gap-6">
          <div className="space-y-4">
            <h4 className="text-lg font-medium text-white">Learning Progress</h4>
            <div className="space-y-3">
              <div>
                <div className="flex justify-between text-sm mb-1">
                  <span className="text-gray-400">Course Completion</span>
                  <span className="text-white">75%</span>
                </div>
                <div className="h-2 bg-gray-600 rounded-full overflow-hidden">
                  <div className="h-full w-3/4 bg-purple-500 rounded-full"></div>
                </div>
              </div>
              <div>
                <div className="flex justify-between text-sm mb-1">
                  <span className="text-gray-400">Assignments</span>
                  <span className="text-white">85%</span>
                </div>
                <div className="h-2 bg-gray-600 rounded-full overflow-hidden">
                  <div className="h-full w-[85%] bg-green-500 rounded-full"></div>
                </div>
              </div>
            </div>
          </div>

          <div className="space-y-4">
            <h4 className="text-lg font-medium text-white">Achievements</h4>
            <div className="grid grid-cols-2 gap-4">
              <div className="bg-gray-700 p-4 rounded-lg">
                <div className="text-2xl font-bold text-purple-400 mb-1">12</div>
                <div className="text-sm text-gray-400">Courses Completed</div>
              </div>
              <div className="bg-gray-700 p-4 rounded-lg">
                <div className="text-2xl font-bold text-green-400 mb-1">85%</div>
                <div className="text-sm text-gray-400">Average Score</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <TokenClaimPopup 
        isOpen={showClaimPopup}
        onClose={() => setShowClaimPopup(false)}
      />
    </div>
  );
}