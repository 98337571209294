import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Course } from '../types/course';
import { courses } from '../data/courses';
import CourseCard from '../components/courses/CourseCard';
import EnrollmentModal from '../components/courses/EnrollmentModal';
import LoginPromptModal from '../components/courses/LoginPromptModal';
import CourseDetailsModal from '../components/courses/CourseDetailsModal';
import { 
  initializeTokens, 
  getTokenBalance, 
  deductTokens, 
  enrollInCourse,
  getEnrolledCourses 
} from '../lib/tokenStore';

export default function CoursesPage() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [userTokens, setUserTokens] = useState(0);
  const [enrolledCourseIds, setEnrolledCourseIds] = useState<string[]>([]);
  const [selectedCourse, setSelectedCourse] = useState<Course | null>(null);
  const [showLoginPrompt, setShowLoginPrompt] = useState(false);
  const [displayedCourses, setDisplayedCourses] = useState<Course[]>([]);
  const [courseDetails, setCourseDetails] = useState<Course | null>(null);

  useEffect(() => {
    const initializePage = async () => {
      try {
        const userId = localStorage.getItem('userId');
        if (userId) {
          await initializeTokens(parseInt(userId));
          const [balance, enrolled] = await Promise.all([
            getTokenBalance(parseInt(userId)),
            getEnrolledCourses(parseInt(userId))
          ]);

          setUserTokens(balance);
          setEnrolledCourseIds(enrolled);
          
          const updatedCourses = courses.map(course => ({
            ...course,
            enrolled: enrolled.includes(course.id),
            progress: 0
          }));
          
          setDisplayedCourses(updatedCourses);
        } else {
          setDisplayedCourses(courses);
        }
      } catch (error) {
        console.error('Failed to initialize page:', error);
      } finally {
        setLoading(false);
      }
    };

    initializePage();
  }, []);

  const handleEnrollClick = (courseId: string) => {
    const userId = localStorage.getItem('userId');
    if (!userId) {
      setShowLoginPrompt(true);
      return;
    }

    const course = courses.find(c => c.id === courseId);
    if (course) {
      setSelectedCourse(course);
    }
  };

  const handleViewDetails = (course: Course) => {
    setCourseDetails(course);
  };

  const handleEnrollConfirm = async () => {
    if (!selectedCourse) return;

    const userId = parseInt(localStorage.getItem('userId') || '0');
    if (!userId) {
      setShowLoginPrompt(true);
      return;
    }

    try {
      const success = await deductTokens(userId, selectedCourse.tokenCost);
      if (success) {
        await enrollInCourse(userId, selectedCourse.id);
        
        const newBalance = await getTokenBalance(userId);
        setUserTokens(newBalance);
        setEnrolledCourseIds(prev => [...prev, selectedCourse.id]);
        
        setDisplayedCourses(prev => 
          prev.map(course => 
            course.id === selectedCourse.id 
              ? { ...course, enrolled: true, progress: 0 }
              : course
          )
        );
        
        setSelectedCourse(null);
      }
    } catch (error) {
      console.error('Failed to enroll in course:', error);
    }
  };

  const handleLoginPromptClose = () => {
    setShowLoginPrompt(false);
  };

  const handleLoginRedirect = () => {
    navigate('/login');
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-900 pt-24 flex items-center justify-center">
        <div className="text-white">Loading...</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-900 pt-24">
      <div className="container mx-auto px-4 py-12">
        <div className="mb-8">
          <h1 className="text-4xl font-bold text-white mb-4">Available Courses</h1>
          <div className="flex items-center justify-between">
            <p className="text-gray-300">
              Explore our collection of tech courses and start learning today.
            </p>
            {userTokens > 0 && (
              <div className="text-purple-400 font-semibold">
                Token Balance: {new Intl.NumberFormat().format(userTokens)}
              </div>
            )}
          </div>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {displayedCourses.map(course => (
            <CourseCard
              key={course.id}
              course={course}
              onEnroll={handleEnrollClick}
              onViewDetails={handleViewDetails}
              disabled={course.enrolled}
            />
          ))}
        </div>

        {selectedCourse && (
          <EnrollmentModal
            course={selectedCourse}
            tokenBalance={userTokens}
            onConfirm={handleEnrollConfirm}
            onClose={() => setSelectedCourse(null)}
          />
        )}

        {courseDetails && (
          <CourseDetailsModal
            course={courseDetails}
            onClose={() => setCourseDetails(null)}
          />
        )}

        <LoginPromptModal
          isOpen={showLoginPrompt}
          onClose={handleLoginPromptClose}
          onLogin={handleLoginRedirect}
        />
      </div>
    </div>
  );
}