import { Course } from '../types/course';

export const courses: Course[] = [
  {
    id: 'cloud-computing-101',
    title: 'Cloud Computing Fundamentals',
    description: 'Master the essentials of cloud computing with hands-on experience in AWS, Azure, and Google Cloud Platform.',
    image: 'https://images.unsplash.com/photo-1451187580459-43490279c0fa?auto=format&fit=crop&q=80',
    duration: '8 weeks',
    level: 'Beginner',
    topics: ['AWS', 'Azure', 'Google Cloud', 'Cloud Architecture', 'Scalability'],
    tokenCost: 1000000,
    content: {
      overview: 'This comprehensive course covers the fundamentals of cloud computing across major platforms.',
      modules: [
        {
          title: 'Introduction to Cloud Computing',
          topics: [
            'Understanding cloud service models (IaaS, PaaS, SaaS)',
            'Cloud deployment models',
            'Benefits and challenges of cloud computing',
            'Cloud security fundamentals'
          ]
        },
        {
          title: 'Amazon Web Services (AWS)',
          topics: [
            'EC2 instance management',
            'S3 storage solutions',
            'AWS Lambda and serverless computing',
            'VPC and networking'
          ]
        },
        {
          title: 'Microsoft Azure',
          topics: [
            'Azure Virtual Machines',
            'Azure App Services',
            'Azure Storage solutions',
            'Azure Active Directory'
          ]
        },
        {
          title: 'Google Cloud Platform',
          topics: [
            'Compute Engine',
            'Cloud Storage',
            'Kubernetes Engine',
            'Cloud Functions'
          ]
        }
      ]
    }
  },
  {
    id: 'ai-ml-fundamentals',
    title: 'AI & Machine Learning Basics',
    description: 'Dive into the world of AI and ML with practical Python implementations and real-world applications.',
    image: 'https://images.unsplash.com/photo-1555255707-c07966088b7b?auto=format&fit=crop&q=80',
    duration: '12 weeks',
    level: 'Intermediate',
    topics: ['Python', 'TensorFlow', 'Neural Networks', 'Deep Learning'],
    tokenCost: 1000000,
    content: {
      overview: 'Learn the fundamentals of AI and ML while building practical applications.',
      modules: [
        {
          title: 'Python for Machine Learning',
          topics: [
            'NumPy and data manipulation',
            'Pandas for data analysis',
            'Data visualization with Matplotlib',
            'Statistical analysis basics'
          ]
        },
        {
          title: 'Machine Learning Fundamentals',
          topics: [
            'Supervised vs Unsupervised learning',
            'Linear regression and classification',
            'Decision trees and random forests',
            'Model evaluation techniques'
          ]
        },
        {
          title: 'Neural Networks',
          topics: [
            'Neural network architecture',
            'Activation functions',
            'Backpropagation',
            'Optimization algorithms'
          ]
        },
        {
          title: 'Deep Learning with TensorFlow',
          topics: [
            'TensorFlow basics',
            'Building neural networks',
            'Convolutional Neural Networks',
            'Transfer learning'
          ]
        }
      ]
    }
  },
  {
    id: 'cybersecurity-essentials',
    title: 'Cybersecurity Essentials',
    description: 'Learn to protect systems and networks from cyber threats with hands-on security training.',
    image: 'https://images.unsplash.com/photo-1550751827-4bd374c3f58b?auto=format&fit=crop&q=80',
    duration: '10 weeks',
    level: 'Beginner',
    topics: ['Network Security', 'Encryption', 'Threat Detection', 'Security Protocols'],
    tokenCost: 1000000,
    content: {
      overview: 'Master the fundamentals of cybersecurity and learn to protect against modern threats.',
      modules: [
        {
          title: 'Security Fundamentals',
          topics: [
            'CIA triad (Confidentiality, Integrity, Availability)',
            'Security policies and procedures',
            'Risk assessment and management',
            'Security controls and frameworks'
          ]
        },
        {
          title: 'Network Security',
          topics: [
            'Network protocols and vulnerabilities',
            'Firewall configuration',
            'Intrusion detection systems',
            'VPN and secure communications'
          ]
        },
        {
          title: 'Cryptography',
          topics: [
            'Encryption algorithms',
            'Public key infrastructure',
            'Digital signatures',
            'Hash functions'
          ]
        },
        {
          title: 'Threat Detection & Response',
          topics: [
            'Malware analysis',
            'Incident response procedures',
            'Security monitoring',
            'Forensics basics'
          ]
        }
      ]
    }
  }
];