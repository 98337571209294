import React from 'react';
import LogoSymbol from './logo/LogoSymbol';
import LogoText from './logo/LogoText';

export default function Logo() {
  return (
    <div className="flex items-center gap-3">
      <LogoSymbol />
      <LogoText />
    </div>
  );
}