import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export function useScrollToSection() {
  const location = useLocation();
  const navigate = useNavigate();

  const handleHashChange = useCallback(() => {
    const hash = location.hash;
    if (hash) {
      const element = document.getElementById(hash.slice(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location.hash]);

  const scrollToSection = useCallback((path: string) => {
    // Check if the path is a URL or a hash
    if (path.startsWith('http') || path.startsWith('https')) {
      window.location.href = path;
      return;
    }

    // Handle internal navigation
    if (path.startsWith('/')) {
      navigate(path);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      const element = document.getElementById(path.replace('#', ''));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [navigate]);

  // Handle initial page load and navigation
  useEffect(() => {
    if (!location.hash) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      handleHashChange();
    }
  }, [location.pathname, location.hash, handleHashChange]);

  return { scrollToSection, handleHashChange };
}