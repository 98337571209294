import { X } from 'lucide-react';

interface LoginPromptModalProps {
  isOpen: boolean;
  onClose: () => void;
  onLogin: () => void;
}

export default function LoginPromptModal({ isOpen, onClose, onLogin }: LoginPromptModalProps) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-4">
      <div 
        className="absolute inset-0 bg-black/50 backdrop-blur-sm"
        onClick={onClose}
      />
      
      <div className="relative bg-gradient-to-br from-purple-900 to-gray-900 rounded-2xl p-8 max-w-md w-full shadow-2xl animate-slideIn">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-white transition-colors"
        >
          <X className="w-6 h-6" />
        </button>

        <div className="text-center space-y-4">
          <h3 className="text-2xl font-bold text-white">Login Required</h3>
          <p className="text-purple-200">
            To enroll in this course, you'll need to log in or create an account first.
            New users get 10 million tokens to start learning!
          </p>

          <div className="flex gap-4 mt-6">
            <button
              onClick={onClose}
              className="flex-1 py-3 px-6 bg-gray-700 hover:bg-gray-600 text-white font-semibold rounded-lg transition-colors"
            >
              Cancel
            </button>
            <button
              onClick={onLogin}
              className="flex-1 py-3 px-6 bg-purple-600 hover:bg-purple-700 text-white font-semibold rounded-lg transition-colors"
            >
              Login / Register
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}