import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowLeft, Clock, Book, Target, Award, Coins } from 'lucide-react';
import { Course } from '../types/course';
import { courses } from '../data/courses';

export default function CourseDetailsPage() {
  const { courseId } = useParams();
  const navigate = useNavigate();
  const [course, setCourse] = useState<Course | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const foundCourse = courses.find(c => c.id === courseId);
    if (foundCourse) {
      setCourse(foundCourse);
    }
    setLoading(false);
  }, [courseId]);

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-900 pt-24 flex items-center justify-center">
        <div className="text-white">Loading...</div>
      </div>
    );
  }

  if (!course) {
    return (
      <div className="min-h-screen bg-gray-900 pt-24">
        <div className="container mx-auto px-4 py-12">
          <button
            onClick={() => navigate('/courses')}
            className="flex items-center gap-2 text-purple-400 hover:text-purple-300 mb-8"
          >
            <ArrowLeft className="w-5 h-5" />
            Back to Courses
          </button>
          <div className="text-center">
            <h1 className="text-3xl font-bold text-white mb-4">Course not found</h1>
            <p className="text-gray-300">The course you're looking for doesn't exist.</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-900 pt-24">
      {/* Hero Section */}
      <div className="relative h-96">
        <img
          src={course.image}
          alt={course.title}
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-gray-900 to-transparent"></div>
        
        <div className="absolute bottom-0 left-0 right-0">
          <div className="container mx-auto px-4 py-8">
            <button
              onClick={() => navigate('/courses')}
              className="flex items-center gap-2 text-white hover:text-purple-300 mb-4"
            >
              <ArrowLeft className="w-5 h-5" />
              Back to Courses
            </button>
            <div className="flex items-center gap-4 mb-4">
              <span className={`px-3 py-1 rounded-full text-sm ${
                course.level === 'Beginner' ? 'bg-green-500/20 text-green-300' :
                course.level === 'Intermediate' ? 'bg-yellow-500/20 text-yellow-300' :
                'bg-red-500/20 text-red-300'
              }`}>
                {course.level}
              </span>
              <span className="text-gray-300 flex items-center gap-1">
                <Clock className="w-4 h-4" />
                {course.duration}
              </span>
              <span className="text-purple-400 flex items-center gap-1">
                <Coins className="w-4 h-4" />
                {new Intl.NumberFormat().format(course.tokenCost)} tokens
              </span>
            </div>
            <h1 className="text-4xl font-bold text-white mb-4">{course.title}</h1>
            <p className="text-xl text-gray-300 max-w-3xl">{course.description}</p>
          </div>
        </div>
      </div>

      {/* Content */}
      <div className="container mx-auto px-4 py-16">
        <div className="max-w-4xl mx-auto space-y-16">
          {/* Overview */}
          <section>
            <h2 className="text-2xl font-bold text-white mb-6 flex items-center gap-2">
              <Target className="w-6 h-6 text-purple-400" />
              Course Overview
            </h2>
            <p className="text-gray-300 leading-relaxed">{course.content?.overview}</p>
          </section>

          {/* Modules */}
          <section>
            <h2 className="text-2xl font-bold text-white mb-6 flex items-center gap-2">
              <Book className="w-6 h-6 text-purple-400" />
              Course Content
            </h2>
            <div className="space-y-6">
              {course.content?.modules.map((module, index) => (
                <div 
                  key={index}
                  className="bg-gray-800/50 backdrop-blur-sm rounded-lg p-6 hover:bg-gray-800/70 transition-colors"
                >
                  <h3 className="text-xl font-semibold text-white mb-4">
                    Module {index + 1}: {module.title}
                  </h3>
                  <ul className="space-y-3">
                    {module.topics.map((topic, topicIndex) => (
                      <li 
                        key={topicIndex}
                        className="flex items-start gap-3 text-gray-300"
                      >
                        <span className="text-purple-400 mt-1">•</span>
                        {topic}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </section>

          {/* Learning Outcomes */}
          <section>
            <h2 className="text-2xl font-bold text-white mb-6 flex items-center gap-2">
              <Award className="w-6 h-6 text-purple-400" />
              What You'll Learn
            </h2>
            <div className="grid md:grid-cols-2 gap-6">
              {course.content?.modules.flatMap((module, index) => 
                module.topics.map((topic, topicIndex) => (
                  <div 
                    key={`${index}-${topicIndex}`}
                    className="flex items-start gap-3 bg-gray-800/30 rounded-lg p-4"
                  >
                    <span className="text-purple-400 mt-1">•</span>
                    <span className="text-gray-300">{topic}</span>
                  </div>
                ))
              )}
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}