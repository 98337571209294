import React from 'react';

export default function LogoSymbol() {
  return (
    <div className="relative w-16 h-16">
      {/* Outer Glow Effect */}
      <div className="absolute inset-0 rounded-full bg-gradient-to-br from-pink-500/20 via-purple-600/20 to-blue-500/20 blur-md"></div>
      
      {/* Main Circular Frame with Gradient */}
      <div className="absolute inset-0.5 rounded-full bg-gradient-to-br from-pink-500 via-purple-600 to-blue-500 animate-pulse-subtle"></div>
      
      {/* Inner Shadow */}
      <div className="absolute inset-1 rounded-full bg-gradient-to-br from-white/10 to-transparent"></div>
      
      {/* T Symbol with enhanced visibility */}
      <div className="absolute inset-0 flex items-center justify-center">
        {/* Vertical bar of T */}
        <div className="relative w-6 h-9">
          <div className="absolute inset-0 bg-gradient-to-b from-pink-400 via-purple-500 to-blue-400 rounded-md shadow-lg"></div>
          {/* Highlight effect */}
          <div className="absolute inset-x-0 top-0 h-1/2 bg-gradient-to-b from-white/30 to-transparent rounded-t-md"></div>
        </div>
        
        {/* Horizontal bar of T */}
        <div className="absolute top-2.5 w-10 h-2">
          <div className="absolute inset-0 bg-gradient-to-r from-pink-400 via-purple-500 to-blue-400 rounded-md shadow-lg"></div>
          {/* Highlight effect */}
          <div className="absolute inset-0 bg-gradient-to-b from-white/30 to-transparent rounded-md"></div>
        </div>
      </div>
    </div>
  );
}