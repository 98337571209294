import React from 'react';

export default function LogoText() {
  return (
    <span className="text-2xl font-bold tracking-wider">
      <span className="bg-gradient-to-r from-pink-400 via-purple-500 to-blue-400 bg-clip-text text-transparent drop-shadow-sm">
        TECH LEARN
      </span>
    </span>
  );
}