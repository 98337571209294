import { Link } from 'react-router-dom';
import { useScrollToSection } from '../hooks/useScrollToSection';

interface ScrollLinkProps {
  to: string;
  className?: string;
  children: React.ReactNode;
}

export default function ScrollLink({ to, className, children }: ScrollLinkProps) {
  const { scrollToSection } = useScrollToSection();

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    scrollToSection(to);
  };

  return (
    <Link
      to={to}
      className={className}
      onClick={handleClick}
    >
      {children}
    </Link>
  );
}