import { BookOpen, Clock, Award } from 'lucide-react';

const courses = [
  {
    id: 1,
    title: 'Introduction to Machine Learning',
    progress: 75,
    totalHours: 20,
    completedHours: 15,
    status: 'In Progress'
  },
  {
    id: 2,
    title: 'Advanced Python Programming',
    progress: 100,
    totalHours: 30,
    completedHours: 30,
    status: 'Completed'
  },
  {
    id: 3,
    title: 'Web Development Fundamentals',
    progress: 40,
    totalHours: 25,
    completedHours: 10,
    status: 'In Progress'
  }
];

export default function MyCourses() {
  return (
    <div className="space-y-8">
      <div className="flex items-center justify-between">
        <h2 className="text-2xl font-bold text-white">My Courses</h2>
        <button className="px-4 py-2 bg-purple-600 hover:bg-purple-700 text-white rounded-lg transition-colors">
          Browse Courses
        </button>
      </div>

      <div className="grid gap-6">
        {courses.map((course) => (
          <div key={course.id} className="bg-gray-700/50 rounded-lg p-6">
            <div className="flex flex-col md:flex-row md:items-center justify-between gap-4">
              <div className="space-y-2">
                <h3 className="text-xl font-semibold text-white">{course.title}</h3>
                <div className="flex items-center gap-4 text-sm text-gray-400">
                  <div className="flex items-center gap-1">
                    <Clock className="w-4 h-4" />
                    <span>{course.completedHours}/{course.totalHours} hours</span>
                  </div>
                  <div className="flex items-center gap-1">
                    <BookOpen className="w-4 h-4" />
                    <span>{course.status}</span>
                  </div>
                </div>
              </div>

              <div className="flex items-center gap-4">
                <div className="flex-1 md:w-48">
                  <div className="flex justify-between text-sm mb-1">
                    <span className="text-gray-400">Progress</span>
                    <span className="text-white">{course.progress}%</span>
                  </div>
                  <div className="h-2 bg-gray-600 rounded-full overflow-hidden">
                    <div 
                      className={`h-full rounded-full ${
                        course.progress === 100 ? 'bg-green-500' : 'bg-purple-500'
                      }`}
                      style={{ width: `${course.progress}%` }}
                    ></div>
                  </div>
                </div>

                {course.progress === 100 && (
                  <Award className="w-6 h-6 text-yellow-400" />
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}