import { useState, useCallback } from 'react';

export function useComingSoon() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const showComingSoon = useCallback(() => {
    setIsPopupOpen(true);
  }, []);

  const hideComingSoon = useCallback(() => {
    setIsPopupOpen(false);
  }, []);

  return {
    isPopupOpen,
    showComingSoon,
    hideComingSoon
  };
}