import { MessageSquare, Sparkles, BookOpen, GraduationCap } from 'lucide-react';
import ComingSoonPopup from './common/ComingSoonPopup';
import { useComingSoon } from '../hooks/useComingSoon';

export default function AiAssistant() {
  const { isPopupOpen, showComingSoon, hideComingSoon } = useComingSoon();

  return (
    <>
      {/* Dedicated Course-Based AI Section */}
      <section className="py-20 bg-gray-900 relative overflow-hidden">
        <div className="container mx-auto px-4">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <div className="relative">
              <div className="relative z-10 bg-gradient-to-br from-purple-900/20 to-purple-600/20 rounded-lg p-2 shadow-2xl">
                <img
                  src="https://images.unsplash.com/photo-1522202176988-66273c2fd55f?auto=format&fit=crop&q=80"
                  alt="Course-Based AI Learning"
                  className="rounded-lg w-full shadow-lg"
                />
                <div className="absolute -top-4 -right-4 w-20 h-20">
                  <div className="absolute inset-0 bg-purple-500 opacity-20 rounded-full animate-ping"></div>
                  <div className="absolute inset-0 bg-purple-500 opacity-40 rounded-full"></div>
                </div>
              </div>
              
              {/* Decorative elements */}
              <div className="absolute -top-10 -right-10 w-40 h-40 bg-purple-600/20 rounded-full blur-3xl"></div>
              <div className="absolute -bottom-10 -left-10 w-40 h-40 bg-purple-600/20 rounded-full blur-3xl"></div>
            </div>

            <div className="relative z-10">
              <div className="flex items-center gap-2 mb-4">
                <BookOpen className="text-purple-400 w-6 h-6" />
                <span className="text-purple-400">Course-Specific Learning</span>
              </div>
              <h2 className="text-4xl md:text-5xl font-bold text-white mb-6">
                Dedicated Course-Based AI
              </h2>
              <p className="text-gray-300 text-lg leading-relaxed mb-8">
                Experience AI that's specifically trained for your chosen course. Our intelligent system 
                adapts to provide precise, contextual answers to your questions, ensuring deep understanding 
                of course materials and concepts.
              </p>
              <button 
                onClick={showComingSoon}
                className="inline-flex items-center bg-purple-600 hover:bg-purple-700 text-white font-semibold px-6 py-3 rounded-lg transition-all"
              >
                <GraduationCap className="w-5 h-5 mr-2" />
                Explore Course AI
              </button>
            </div>
          </div>
        </div>
      </section>

      {/* Original AI Assistant Section */}
      <section className="py-20 bg-gray-900 relative overflow-hidden" id="ai-assistant">
        <div className="container mx-auto px-4">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <div className="relative z-10">
              <div className="flex items-center gap-2 mb-4">
                <Sparkles className="text-purple-400 w-6 h-6" />
                <span className="text-purple-400">AI Assistant</span>
              </div>
              <h2 className="text-4xl md:text-5xl font-bold text-white mb-6">
                AI Assistant: Your Personal Lab Guide
              </h2>
              <p className="text-gray-300 text-lg leading-relaxed mb-8">
                Master complex lab tasks with ease using the AI Assistant. Whether you're stuck or unsure, 
                just speak to the AI as you would with a friend. It provides real-time feedback, highlighting 
                exactly where things went wrong and guiding you back on track.
              </p>
              <button 
                onClick={showComingSoon}
                className="inline-flex items-center bg-purple-600 hover:bg-purple-700 text-white font-semibold px-6 py-3 rounded-lg transition-all"
              >
                <MessageSquare className="w-5 h-5 mr-2" />
                Try AI Assistant Now
              </button>
            </div>
            
            <div className="relative">
              <div className="relative z-10 bg-gradient-to-br from-purple-900/20 to-purple-600/20 rounded-lg p-2 shadow-2xl">
                <img
                  src="https://images.unsplash.com/photo-1676299081847-824916de030a?auto=format&fit=crop&q=80"
                  alt="AI Assistant Interface"
                  className="rounded-lg w-full shadow-lg"
                />
                <div className="absolute -top-4 -right-4 w-20 h-20">
                  <div className="absolute inset-0 bg-purple-500 opacity-20 rounded-full animate-ping"></div>
                  <div className="absolute inset-0 bg-purple-500 opacity-40 rounded-full"></div>
                </div>
              </div>
              
              {/* Decorative elements */}
              <div className="absolute -top-10 -right-10 w-40 h-40 bg-purple-600/20 rounded-full blur-3xl"></div>
              <div className="absolute -bottom-10 -left-10 w-40 h-40 bg-purple-600/20 rounded-full blur-3xl"></div>
            </div>
          </div>
        </div>
      </section>

      <ComingSoonPopup 
        isOpen={isPopupOpen}
        onClose={hideComingSoon}
      />
    </>
  );
}