import { X } from 'lucide-react';

interface ComingSoonPopupProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function ComingSoonPopup({ isOpen, onClose }: ComingSoonPopupProps) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-4">
      <div 
        className="absolute inset-0 bg-black/50 backdrop-blur-sm"
        onClick={onClose}
      />
      
      <div className="relative bg-gradient-to-br from-purple-900 to-gray-900 rounded-2xl p-8 max-w-md w-full shadow-2xl animate-slideIn">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-white transition-colors"
        >
          <X className="w-6 h-6" />
        </button>

        <div className="text-center space-y-4">
          <div className="text-4xl mb-2">🚀</div>
          <h3 className="text-2xl font-bold text-white">Something exciting is on the horizon!</h3>
          <p className="text-purple-200">
            Stay tuned—our app is almost ready to revolutionize your experience.
          </p>
        </div>
      </div>
    </div>
  );
}