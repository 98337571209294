import { X, Coins } from 'lucide-react';

interface TokenClaimPopupProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function TokenClaimPopup({ isOpen, onClose }: TokenClaimPopupProps) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-4">
      <div 
        className="absolute inset-0 bg-black/50 backdrop-blur-sm"
        onClick={onClose}
      />
      
      <div className="relative bg-gradient-to-br from-purple-900 to-gray-900 rounded-2xl p-8 max-w-md w-full shadow-2xl animate-slideIn">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-white transition-colors"
        >
          <X className="w-6 h-6" />
        </button>

        <div className="text-center space-y-4">
          <div className="flex justify-center mb-4">
            <div className="bg-purple-500/20 p-4 rounded-full">
              <Coins className="w-12 h-12 text-purple-400" />
            </div>
          </div>
          
          <h3 className="text-2xl font-bold text-white">Congratulations! 🎉</h3>
          <p className="text-purple-200">
            You've received 2M tokens. Enjoy the first-ever AI learning experience in Bangladesh!
          </p>

          <button
            onClick={onClose}
            className="w-full py-3 px-6 bg-purple-600 hover:bg-purple-700 text-white font-semibold rounded-lg transition-colors mt-4"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}