import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import Hero from './components/Hero';
import AiAssistant from './components/AiAssistant';
import AiTutor from './components/AiTutor';
import MultipleLanguages from './components/MultipleLanguages';
import MobileApps from './components/MobileApps';
import BenefitDetails from './components/BenefitDetails';
import LoginForm from './components/auth/LoginForm';
import RegisterForm from './components/auth/RegisterForm';
import Dashboard from './components/dashboard/Dashboard';
import ContactPage from './pages/Contact';
import AboutPage from './pages/About';
import BenefitsPage from './pages/Benefits';
import HowItWorksPage from './pages/HowItWorks';
import PricingPage from './pages/Pricing';
import CoursesPage from './pages/Courses';
import CourseDetailsPage from './pages/CourseDetails';
import { useScrollToSection } from './hooks/useScrollToSection';

function ScrollToTop() {
  const { pathname } = useLocation();
  const { handleHashChange } = useScrollToSection();

  useEffect(() => {
    window.scrollTo(0, 0);
    handleHashChange();
  }, [pathname, handleHashChange]);

  return null;
}

function HomePage() {
  return (
    <>
      <Hero />
      <AiAssistant />
      <AiTutor />
      <MultipleLanguages />
      <MobileApps />
    </>
  );
}

function App() {
  return (
    <Router>
      <div className="gradient-bg text-white min-h-screen flex flex-col">
        <Navigation />
        <ScrollToTop />
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/benefits" element={<BenefitsPage />} />
            <Route path="/how-it-works" element={<HowItWorksPage />} />
            <Route path="/pricing" element={<PricingPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/login" element={<LoginForm />} />
            <Route path="/register" element={<RegisterForm />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/courses" element={<CoursesPage />} />
            <Route path="/courses/:courseId" element={<CourseDetailsPage />} />
            <Route 
              path="/benefits/:id" 
              element={<BenefitDetails id={window.location.pathname.split('/').pop() || ''} />}
            />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;