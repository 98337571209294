import { Lightbulb, BookOpen, Trophy, ArrowRight } from 'lucide-react';
import WaveAnimation from '../components/WaveAnimation';

const steps = [
  {
    icon: Lightbulb,
    title: 'Assess Your Level',
    description: 'Take our AI-powered assessment to determine your starting point',
    details: 'Our advanced AI system evaluates your current knowledge and skills through an interactive assessment, ensuring you start at the perfect level.'
  },
  {
    icon: BookOpen,
    title: 'Personalized Learning Path',
    description: 'Follow your custom curriculum designed by our AI system',
    details: 'Based on your assessment, we create a tailored learning path that adapts in real-time to your progress and learning style.'
  },
  {
    icon: Trophy,
    title: 'Track Progress',
    description: 'Monitor your achievements and adjust your learning journey',
    details: 'Watch your skills grow with detailed analytics and progress tracking, celebrating milestones along the way.'
  }
];

export default function HowItWorksPage() {
  return (
    <div className="min-h-screen bg-gray-900 pt-24 relative overflow-hidden">
      <WaveAnimation />
      
      <div className="container mx-auto px-4 py-12 relative z-10">
        <div className="text-center mb-16">
          <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
            How It Works
          </h1>
          <p className="text-xl text-gray-300 max-w-3xl mx-auto">
            Experience a revolutionary learning journey powered by AI technology
            that adapts to your unique needs.
          </p>
        </div>

        <div className="max-w-5xl mx-auto">
          {steps.map((step, index) => (
            <div key={index} className="relative mb-12 last:mb-0">
              <div className="bg-gray-800/50 backdrop-blur-sm rounded-lg p-8">
                <div className="flex flex-col md:flex-row gap-8 items-start">
                  <div className="flex-shrink-0">
                    <div className="w-16 h-16 bg-purple-600/20 rounded-lg flex items-center justify-center">
                      <step.icon className="w-8 h-8 text-purple-400" />
                    </div>
                  </div>
                  <div>
                    <h3 className="text-2xl font-bold text-white mb-4">
                      {step.title}
                    </h3>
                    <p className="text-xl text-purple-200 mb-4">
                      {step.description}
                    </p>
                    <p className="text-gray-300 leading-relaxed">
                      {step.details}
                    </p>
                  </div>
                </div>
              </div>
              
              {index < steps.length - 1 && (
                <div className="hidden md:block absolute left-8 bottom-0 transform translate-y-full h-12">
                  <ArrowRight className="w-6 h-6 text-purple-400 animate-bounce" />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}