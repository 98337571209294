import { Check } from 'lucide-react';
import WaveAnimation from '../components/WaveAnimation';

const plans = [
  {
    name: 'Basic',
    price: '$29',
    features: [
      'Access to basic courses',
      'AI-powered recommendations',
      'Progress tracking',
      'Community support'
    ]
  },
  {
    name: 'Pro',
    price: '$79',
    popular: true,
    features: [
      'All Basic features',
      'Advanced AI assessments',
      'Personalized learning paths',
      'Priority support',
      'Certificate of completion'
    ]
  },
  {
    name: 'Enterprise',
    price: 'Custom',
    features: [
      'All Pro features',
      'Custom learning paths',
      'Team analytics',
      'API access',
      'Dedicated support'
    ]
  }
];

export default function PricingPage() {
  return (
    <div className="min-h-screen bg-gray-900 pt-24 relative overflow-hidden">
      <WaveAnimation />
      
      <div className="container mx-auto px-4 py-12 relative z-10">
        <div className="text-center mb-16">
          <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
            Simple, Transparent Pricing
          </h1>
          <p className="text-xl text-gray-300 max-w-3xl mx-auto">
            Choose the perfect plan for your learning journey. All plans include
            access to our AI-powered learning platform.
          </p>
        </div>

        <div className="grid md:grid-cols-3 gap-8 max-w-6xl mx-auto">
          {plans.map((plan, index) => (
            <div
              key={index}
              className={`relative p-8 rounded-lg ${
                plan.popular
                  ? 'bg-purple-600 transform scale-105'
                  : 'bg-gray-800/50 backdrop-blur-sm'
              }`}
            >
              {plan.popular && (
                <div className="absolute -top-4 left-1/2 transform -translate-x-1/2">
                  <span className="bg-purple-800 text-white text-sm font-semibold px-4 py-1 rounded-full">
                    Most Popular
                  </span>
                </div>
              )}
              
              <h3 className="text-2xl font-bold text-white mb-4">{plan.name}</h3>
              <div className="text-4xl font-bold text-white mb-6">{plan.price}</div>
              <ul className="space-y-4 mb-8">
                {plan.features.map((feature, idx) => (
                  <li key={idx} className="flex items-start text-gray-300">
                    <Check className="w-5 h-5 text-purple-400 mr-2 flex-shrink-0" />
                    {feature}
                  </li>
                ))}
              </ul>
              <button
                className={`w-full py-3 px-6 rounded-lg font-semibold transition-all ${
                  plan.popular
                    ? 'bg-white text-purple-600 hover:bg-gray-100'
                    : 'bg-purple-600 text-white hover:bg-purple-700'
                }`}
              >
                Get Started
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}