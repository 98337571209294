import { openDB } from 'idb';

const DB_NAME = 'tech-learn-academy';
const TOKEN_STORE = 'tokens';
const ENROLLMENT_STORE = 'enrollments';
const DB_VERSION = 3;

const dbPromise = openDB(DB_NAME, DB_VERSION, {
  upgrade(db) {
    // Token store and enrollment store are created in db.ts
  }
});

export async function initializeTokens(userId: number): Promise<void> {
  if (!userId) return;

  const db = await dbPromise;
  const tx = db.transaction(TOKEN_STORE, 'readwrite');
  const store = tx.objectStore(TOKEN_STORE);
  
  try {
    const existing = await store.get(userId);
    if (!existing) {
      await store.add({
        userId,
        balance: 0,
        claimed: false,
        lastUpdated: new Date().toISOString()
      });
    }
    await tx.done;
  } catch (error) {
    console.error('Failed to initialize tokens:', error);
    await tx.abort();
    throw error;
  }
}

export async function hasClaimedTokens(userId: number): Promise<boolean> {
  if (!userId) return false;

  const db = await dbPromise;
  const tx = db.transaction(TOKEN_STORE, 'readonly');
  const store = tx.objectStore(TOKEN_STORE);
  
  try {
    const record = await store.get(userId);
    await tx.done;
    return record?.claimed ?? false;
  } catch (error) {
    console.error('Failed to check token claim status:', error);
    throw error;
  }
}

export async function getTokenBalance(userId: number): Promise<number> {
  if (!userId) return 0;

  const db = await dbPromise;
  const tx = db.transaction(TOKEN_STORE, 'readonly');
  const store = tx.objectStore(TOKEN_STORE);
  
  try {
    const record = await store.get(userId);
    await tx.done;
    return record?.balance ?? 0;
  } catch (error) {
    console.error('Failed to get token balance:', error);
    throw error;
  }
}

export async function claimTokens(userId: number, amount: number): Promise<boolean> {
  if (!userId) return false;

  const db = await dbPromise;
  const tx = db.transaction(TOKEN_STORE, 'readwrite');
  const store = tx.objectStore(TOKEN_STORE);
  
  try {
    const record = await store.get(userId);
    
    if (!record) {
      await store.add({
        userId,
        balance: amount,
        claimed: true,
        lastUpdated: new Date().toISOString()
      });
    } else if (!record.claimed) {
      await store.put({
        ...record,
        balance: record.balance + amount,
        claimed: true,
        lastUpdated: new Date().toISOString()
      });
    } else {
      return false; // Already claimed
    }
    
    await tx.done;
    return true;
  } catch (error) {
    console.error('Failed to claim tokens:', error);
    await tx.abort();
    throw error;
  }
}

export async function deductTokens(userId: number, amount: number): Promise<boolean> {
  if (!userId) return false;

  const db = await dbPromise;
  const tx = db.transaction(TOKEN_STORE, 'readwrite');
  const store = tx.objectStore(TOKEN_STORE);
  
  try {
    const record = await store.get(userId);
    if (!record || record.balance < amount) {
      await tx.done;
      return false;
    }
    
    await store.put({
      ...record,
      balance: record.balance - amount,
      lastUpdated: new Date().toISOString()
    });
    
    await tx.done;
    return true;
  } catch (error) {
    console.error('Failed to deduct tokens:', error);
    await tx.abort();
    throw error;
  }
}

export async function enrollInCourse(userId: number, courseId: string): Promise<boolean> {
  if (!userId || !courseId) return false;

  const db = await dbPromise;
  const tx = db.transaction(ENROLLMENT_STORE, 'readwrite');
  const store = tx.objectStore(ENROLLMENT_STORE);
  
  try {
    await store.add({
      userId,
      courseId,
      enrolledAt: new Date().toISOString(),
      progress: 0
    });
    
    await tx.done;
    return true;
  } catch (error) {
    console.error('Failed to enroll in course:', error);
    await tx.abort();
    throw error;
  }
}

export async function getEnrolledCourses(userId: number): Promise<string[]> {
  if (!userId) return [];

  const db = await dbPromise;
  const tx = db.transaction(ENROLLMENT_STORE, 'readonly');
  const store = tx.objectStore(ENROLLMENT_STORE);
  const index = store.index('userId');
  
  try {
    const enrollments = await index.getAll(userId);
    await tx.done;
    return enrollments.map(enrollment => enrollment.courseId);
  } catch (error) {
    console.error('Failed to get enrolled courses:', error);
    throw error;
  }
}