import { useState, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { Book, GraduationCap, Settings as SettingsIcon, User, LogOut } from 'lucide-react';
import Profile from './Profile';
import MyCourses from './MyCourses';
import SettingsPanel from './Settings';

type TabType = 'profile' | 'courses' | 'settings';

export default function Dashboard() {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<TabType>('profile');
  const [user, setUser] = useState<any>(null);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  // Redirect to login if not authenticated
  if (!localStorage.getItem('auth_token')) {
    return <Navigate to="/login" replace />;
  }

  const handleLogout = () => {
    // Clear authentication data
    localStorage.removeItem('auth_token');
    localStorage.removeItem('user');
    // Redirect to home page
    navigate('/', { replace: true });
  };

  const tabs = [
    { id: 'profile', label: 'Profile', icon: User },
    { id: 'courses', label: 'My Courses', icon: Book },
    { id: 'settings', label: 'Settings', icon: SettingsIcon },
  ];

  return (
    <div className="min-h-screen bg-gray-900 pt-16">
      <div className="container mx-auto px-4 py-8">
        <div className="flex flex-col md:flex-row gap-8">
          {/* Sidebar */}
          <div className="w-full md:w-64 bg-gray-800 rounded-lg p-6">
            <div className="flex items-center gap-3 mb-8">
              <GraduationCap className="w-8 h-8 text-purple-400" />
              <div>
                <h3 className="text-white font-semibold">{user?.name}</h3>
                <p className="text-gray-400 text-sm">{user?.email}</p>
              </div>
            </div>
            
            <nav className="space-y-2">
              {tabs.map((tab) => (
                <button
                  key={tab.id}
                  onClick={() => setActiveTab(tab.id as TabType)}
                  className={`w-full flex items-center gap-3 px-4 py-3 rounded-lg transition-colors ${
                    activeTab === tab.id
                      ? 'bg-purple-600 text-white'
                      : 'text-gray-300 hover:bg-gray-700'
                  }`}
                >
                  <tab.icon className="w-5 h-5" />
                  {tab.label}
                </button>
              ))}

              {/* Logout Button */}
              <button
                onClick={handleLogout}
                className="w-full flex items-center gap-3 px-4 py-3 rounded-lg transition-colors text-red-400 hover:bg-red-500/10 hover:text-red-300"
              >
                <LogOut className="w-5 h-5" />
                Logout
              </button>
            </nav>
          </div>

          {/* Main Content */}
          <div className="flex-1 bg-gray-800 rounded-lg p-6">
            {activeTab === 'profile' && <Profile user={user} />}
            {activeTab === 'courses' && <MyCourses />}
            {activeTab === 'settings' && <SettingsPanel user={user} />}
          </div>
        </div>
      </div>
    </div>
  );
}