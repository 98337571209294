import { openDB } from 'idb';

const DB_NAME = 'tech-learn-academy';
const USER_STORE = 'users';
const TOKEN_STORE = 'tokens';
const ENROLLMENT_STORE = 'enrollments';
const DB_VERSION = 3;

const dbPromise = openDB(DB_NAME, DB_VERSION, {
  upgrade(db, oldVersion, newVersion) {
    // Create or update users store
    if (!db.objectStoreNames.contains(USER_STORE)) {
      const userStore = db.createObjectStore(USER_STORE, {
        keyPath: 'id',
        autoIncrement: true
      });
      userStore.createIndex('email', 'email', { unique: true });
      userStore.createIndex('emailLookup', 'email', { unique: true });
    }
    
    // Create or update tokens store
    if (!db.objectStoreNames.contains(TOKEN_STORE)) {
      const tokenStore = db.createObjectStore(TOKEN_STORE, { keyPath: 'userId' });
      tokenStore.createIndex('userId', 'userId', { unique: true });
    }

    // Create or update enrollments store
    if (!db.objectStoreNames.contains(ENROLLMENT_STORE)) {
      const enrollmentStore = db.createObjectStore(ENROLLMENT_STORE, { 
        keyPath: ['userId', 'courseId'] 
      });
      enrollmentStore.createIndex('userId', 'userId');
      enrollmentStore.createIndex('courseId', 'courseId');
    }
  }
});

interface User {
  id?: number;
  email: string;
  password: string;
  name?: string;
  createdAt?: string;
  updatedAt?: string;
}

export async function createUser(userData: Omit<User, 'id' | 'createdAt' | 'updatedAt'>): Promise<User> {
  const db = await dbPromise;
  const tx = db.transaction([USER_STORE, TOKEN_STORE], 'readwrite');
  const userStore = tx.objectStore(USER_STORE);
  const tokenStore = tx.objectStore(TOKEN_STORE);
  
  try {
    // Check if user exists
    const existingUser = await userStore.index('emailLookup').get(userData.email);
    if (existingUser) {
      throw new Error('User already exists');
    }
    
    // Create new user
    const user: User = {
      ...userData,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    };
    
    const id = await userStore.add(user);
    
    // Initialize tokens for new user with 0 balance
    await tokenStore.add({
      userId: id,
      balance: 0,
      claimed: false,
      lastUpdated: new Date().toISOString()
    });
    
    await tx.done;
    return { ...user, id: id as number };
  } catch (error) {
    await tx.abort();
    throw error;
  }
}

export async function getUserByEmail(email: string): Promise<User | undefined> {
  const db = await dbPromise;
  const tx = db.transaction(USER_STORE, 'readonly');
  const store = tx.objectStore(USER_STORE);
  
  try {
    const user = await store.index('emailLookup').get(email);
    await tx.done;
    return user || undefined;
  } catch (error) {
    await tx.abort();
    throw error;
  }
}

export async function getUserById(id: number): Promise<User | undefined> {
  const db = await dbPromise;
  const tx = db.transaction(USER_STORE, 'readonly');
  const store = tx.objectStore(USER_STORE);
  
  try {
    const user = await store.get(id);
    await tx.done;
    return user || undefined;
  } catch (error) {
    await tx.abort();
    throw error;
  }
}

// Initialize the database connection
dbPromise.catch(error => {
  console.error('Failed to open database:', error);
});