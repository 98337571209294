import { Book, Clock, Award, Coins, Info } from 'lucide-react';
import { Link } from 'react-router-dom';
import { Course } from '../../types/course';

interface CourseCardProps {
  course: Course;
  onEnroll: (courseId: string) => void;
  disabled?: boolean;
}

export default function CourseCard({ course, onEnroll, disabled }: CourseCardProps) {
  const formatTokens = (tokens: number) => {
    return new Intl.NumberFormat().format(tokens);
  };

  return (
    <div className="bg-gray-800/50 backdrop-blur-sm rounded-lg overflow-hidden transition-all hover:transform hover:scale-[1.02]">
      <img
        src={course.image}
        alt={course.title}
        className="w-full h-48 object-cover"
      />
      
      <div className="p-6">
        <div className="flex items-start justify-between mb-4">
          <h3 className="text-xl font-bold text-white">{course.title}</h3>
          <span className={`px-3 py-1 rounded-full text-sm ${
            course.level === 'Beginner' ? 'bg-green-500/20 text-green-300' :
            course.level === 'Intermediate' ? 'bg-yellow-500/20 text-yellow-300' :
            'bg-red-500/20 text-red-300'
          }`}>
            {course.level}
          </span>
        </div>

        <p className="text-gray-300 mb-4">{course.description}</p>

        <div className="space-y-3 mb-6">
          <div className="flex items-center text-gray-400">
            <Clock className="w-4 h-4 mr-2" />
            <span>{course.duration}</span>
          </div>
          <div className="flex items-center text-gray-400">
            <Book className="w-4 h-4 mr-2" />
            <span>{course.topics.join(', ')}</span>
          </div>
          <div className="flex items-center text-purple-400">
            <Coins className="w-4 h-4 mr-2" />
            <span>{formatTokens(course.tokenCost)} tokens</span>
          </div>
        </div>

        <div className="space-y-3">
          <Link
            to={`/courses/${course.id}`}
            className="w-full py-3 px-6 bg-gray-700 hover:bg-gray-600 text-white font-semibold rounded-lg transition-colors flex items-center justify-center gap-2"
          >
            <Info className="w-4 h-4" />
            Course Details
          </Link>

          {course.enrolled ? (
            <div className="flex items-center justify-between">
              <div className="flex items-center text-green-400">
                <Award className="w-5 h-5 mr-2" />
                <span>Enrolled</span>
              </div>
              <div className="text-gray-400">
                Progress: {course.progress}%
              </div>
            </div>
          ) : (
            <button
              onClick={() => onEnroll(course.id)}
              disabled={disabled}
              className="w-full py-3 px-6 bg-purple-600 hover:bg-purple-700 disabled:bg-gray-600 
                disabled:cursor-not-allowed text-white font-semibold rounded-lg transition-colors"
            >
              Enroll Now
            </button>
          )}
        </div>
      </div>
    </div>
  );
}