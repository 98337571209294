import { X, Clock, Book, Target, Award, ArrowLeft } from 'lucide-react';
import { Course } from '../../types/course';

interface CourseDetailsModalProps {
  course: Course;
  onClose: () => void;
}

export default function CourseDetailsModal({ course, onClose }: CourseDetailsModalProps) {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-4 overflow-y-auto">
      <div 
        className="absolute inset-0 bg-black/50 backdrop-blur-sm"
        onClick={onClose}
      />
      
      <div className="relative bg-gradient-to-br from-purple-900 to-gray-900 rounded-2xl w-full max-w-4xl shadow-2xl animate-slideIn m-4">
        {/* Header Image */}
        <div className="relative h-48 rounded-t-2xl overflow-hidden">
          <img
            src={course.image}
            alt={course.title}
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-black/50"></div>
          <button
            onClick={onClose}
            className="absolute top-4 right-4 text-white hover:text-gray-200 transition-colors"
          >
            <X className="w-6 h-6" />
          </button>
        </div>

        {/* Content */}
        <div className="p-8 space-y-8">
          {/* Course Header */}
          <div>
            <div className="flex items-center gap-2 mb-2">
              <span className={`px-3 py-1 rounded-full text-sm ${
                course.level === 'Beginner' ? 'bg-green-500/20 text-green-300' :
                course.level === 'Intermediate' ? 'bg-yellow-500/20 text-yellow-300' :
                'bg-red-500/20 text-red-300'
              }`}>
                {course.level}
              </span>
              <span className="text-gray-400 flex items-center gap-1">
                <Clock className="w-4 h-4" />
                {course.duration}
              </span>
            </div>
            <h2 className="text-3xl font-bold text-white mb-4">{course.title}</h2>
            <p className="text-gray-300">{course.description}</p>
          </div>

          {/* Course Overview */}
          <div>
            <h3 className="text-xl font-semibold text-white mb-4 flex items-center gap-2">
              <Target className="w-5 h-5 text-purple-400" />
              Course Overview
            </h3>
            <p className="text-gray-300">{course.content?.overview}</p>
          </div>

          {/* Course Modules */}
          <div>
            <h3 className="text-xl font-semibold text-white mb-4 flex items-center gap-2">
              <Book className="w-5 h-5 text-purple-400" />
              Course Content
            </h3>
            <div className="space-y-4">
              {course.content?.modules.map((module, index) => (
                <div key={index} className="bg-gray-800/50 rounded-lg p-4">
                  <h4 className="text-white font-semibold mb-2">{module.title}</h4>
                  <ul className="list-disc list-inside text-gray-300 space-y-1">
                    {module.topics.map((topic, topicIndex) => (
                      <li key={topicIndex}>{topic}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>

          {/* What You'll Learn */}
          <div>
            <h3 className="text-xl font-semibold text-white mb-4 flex items-center gap-2">
              <Award className="w-5 h-5 text-purple-400" />
              What You'll Learn
            </h3>
            <ul className="grid md:grid-cols-2 gap-4">
              {course.content?.modules.flatMap((module, index) => 
                module.topics.map((topic, topicIndex) => (
                  <li 
                    key={`${index}-${topicIndex}`}
                    className="flex items-start gap-2 text-gray-300"
                  >
                    <span className="text-purple-400 mt-1">•</span>
                    {topic}
                  </li>
                ))
              )}
            </ul>
          </div>

          {/* Footer Actions */}
          <div className="flex justify-between items-center pt-4 border-t border-gray-700">
            <button
              onClick={onClose}
              className="flex items-center gap-2 text-gray-400 hover:text-white transition-colors"
            >
              <ArrowLeft className="w-5 h-5" />
              Back to Course List
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}