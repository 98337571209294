import { ArrowLeft } from 'lucide-react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

interface BenefitDetailsProps {
  id: string;
}

const benefitsData = {
  'personalized-learning': {
    title: 'Personalized Learning',
    description: 'AI-driven content tailored to your learning style and pace',
    content: {
      overview: 'Our AI-powered platform analyzes your learning patterns, strengths, and areas for improvement to create a truly personalized educational experience. Using advanced machine learning algorithms, we adapt content delivery and difficulty levels in real-time to match your unique learning style.',
      features: [
        'Adaptive learning algorithms that adjust to your pace',
        'Custom content recommendations based on your progress',
        'Learning style assessment and optimization',
        'Dynamic difficulty adjustment',
        'Personalized study schedules',
        'Real-time progress tracking',
        'Interactive learning paths'
      ],
      benefits: [
        'Learn at your own pace without pressure',
        'Focus on topics that matter most to you',
        'Improve retention through personalized repetition',
        'Build confidence with adaptive challenges',
        'Save time with optimized learning paths'
      ],
      image: 'https://images.unsplash.com/photo-1501504905252-473c47e087f8?auto=format&fit=crop&q=80'
    }
  },
  'targeted-progress': {
    title: 'Targeted Progress',
    description: 'Focus on areas that need improvement with smart recommendations',
    content: {
      overview: "Our intelligent system identifies knowledge gaps and creates targeted learning paths to help you improve efficiently. Through continuous assessment and adaptive recommendations, we ensure you're always working on the most impactful areas for your growth.",
      features: [
        'Detailed progress analytics',
        'Skill gap analysis',
        'Custom improvement plans',
        'Progress milestone tracking',
        'Performance insights dashboard',
        'Targeted practice exercises',
        'Weakness identification and remediation'
      ],
      benefits: [
        'Identify and address knowledge gaps quickly',
        'Track your progress with detailed metrics',
        'Receive personalized improvement suggestions',
        'Celebrate achievements with milestone tracking',
        'Optimize your learning journey'
      ],
      image: 'https://images.unsplash.com/photo-1460925895917-afdab827c52f?auto=format&fit=crop&q=80'
    }
  },
  'learn-faster': {
    title: 'Learn Faster',
    description: 'Optimize your learning journey with efficient study paths',
    content: {
      overview: 'Accelerate your learning through optimized study sequences and proven learning techniques integrated into our platform. Our AI-powered system helps you master concepts more quickly by presenting information in the most effective order and format for your learning style.',
      features: [
        'Spaced repetition algorithms',
        'Microlearning modules',
        'Interactive practice sessions',
        'Real-time learning optimization',
        'Focus mode and time management tools',
        'Quick review sessions',
        'Concept relationship mapping'
      ],
      benefits: [
        'Reduce learning time significantly',
        'Improve information retention',
        'Maintain consistent progress',
        'Achieve goals more quickly',
        'Balance learning with other commitments'
      ],
      image: 'https://images.unsplash.com/photo-1434030216411-0b793f4b4173?auto=format&fit=crop&q=80'
    }
  },
  'instant-feedback': {
    title: 'Instant Feedback',
    description: 'Real-time assessment and adaptive learning paths',
    content: {
      overview: 'Get immediate, actionable feedback on your progress and understanding, helping you learn more effectively. Our AI system provides detailed explanations and suggestions for improvement, ensuring you never get stuck or frustrated during your learning journey.',
      features: [
        'Real-time performance analysis',
        'Interactive assessments',
        'Detailed explanation of mistakes',
        'Progress recommendations',
        'Adaptive difficulty scaling',
        'Instant concept clarification',
        'Personalized error correction'
      ],
      benefits: [
        'Learn from mistakes immediately',
        'Build confidence through understanding',
        'Maintain momentum in learning',
        'Prevent misconception formation',
        'Accelerate skill mastery'
      ],
      image: 'https://images.unsplash.com/photo-1516321318423-f06f85e504b3?auto=format&fit=crop&q=80'
    }
  }
};

export default function BenefitDetails({ id }: BenefitDetailsProps) {
  const benefit = benefitsData[id as keyof typeof benefitsData];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!benefit) {
    return (
      <div className="min-h-screen bg-gray-900 pt-24 pb-12 flex items-center justify-center">
        <div className="text-center">
          <h1 className="text-3xl font-bold text-white mb-4">Benefit not found</h1>
          <Link
            to="/#benefits"
            className="inline-flex items-center text-purple-400 hover:text-purple-300"
          >
            <ArrowLeft className="w-5 h-5 mr-2" />
            Back to Benefits
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-900 pt-24 pb-12">
      <div className="container mx-auto px-4">
        <Link
          to="/#benefits"
          className="inline-flex items-center text-purple-400 hover:text-purple-300 mb-8"
        >
          <ArrowLeft className="w-5 h-5 mr-2" />
          Back to Benefits
        </Link>
        
        <div className="grid md:grid-cols-2 gap-12">
          <div>
            <h1 className="text-4xl font-bold text-white mb-4">{benefit.title}</h1>
            <p className="text-xl text-purple-200 mb-8">{benefit.description}</p>
            <p className="text-gray-300 mb-8 leading-relaxed">{benefit.content.overview}</p>
            
            <div className="space-y-8">
              <div>
                <h2 className="text-2xl font-semibold text-white mb-4">Key Features</h2>
                <ul className="space-y-3">
                  {benefit.content.features.map((feature, index) => (
                    <li key={index} className="flex items-start text-gray-300">
                      <span className="text-purple-400 mr-2">•</span>
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
              
              <div>
                <h2 className="text-2xl font-semibold text-white mb-4">Benefits</h2>
                <ul className="space-y-3">
                  {benefit.content.benefits.map((item, index) => (
                    <li key={index} className="flex items-start text-gray-300">
                      <span className="text-purple-400 mr-2">•</span>
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          
          <div className="space-y-8">
            <img
              src={benefit.content.image}
              alt={benefit.title}
              className="rounded-lg shadow-xl w-full h-auto"
            />
            
            <div className="bg-gray-800 p-6 rounded-lg">
              <h3 className="text-xl font-semibold text-white mb-4">Ready to Start?</h3>
              <p className="text-gray-300 mb-6">
                Experience the power of {benefit.title.toLowerCase()} and transform your learning journey today.
              </p>
              <button className="w-full bg-purple-600 hover:bg-purple-700 text-white font-semibold py-3 px-6 rounded-lg transition-all">
                Get Started Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}