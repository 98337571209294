import { Sparkles, Brain, Cpu } from 'lucide-react';
import WaveAnimation from '../components/WaveAnimation';

export default function AboutPage() {
  return (
    <div className="min-h-screen bg-gray-900 pt-24 relative overflow-hidden">
      <WaveAnimation />
      
      <div className="container mx-auto px-4 py-12 relative z-10">
        <div className="max-w-4xl mx-auto">
          <div className="text-center mb-16">
            <div className="flex items-center justify-center gap-2 mb-4">
              <Sparkles className="text-purple-400 w-6 h-6" />
              <span className="text-purple-400">About Us</span>
            </div>
            <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
              Revolutionizing Tech Education
            </h1>
            <p className="text-xl text-gray-300">
              We're on a mission to transform how people learn technology through 
              AI-powered personalized education.
            </p>
          </div>

          <div className="space-y-16">
            <div className="bg-gray-800/50 backdrop-blur-sm rounded-lg p-8">
              <div className="flex items-start gap-6">
                <Brain className="w-12 h-12 text-purple-400 flex-shrink-0" />
                <div>
                  <h2 className="text-2xl font-bold text-white mb-4">Our Vision</h2>
                  <p className="text-gray-300 leading-relaxed">
                    We envision a world where quality tech education is accessible to everyone, 
                    regardless of their background or location. Through our AI-powered platform, 
                    we're making this vision a reality by providing personalized learning experiences 
                    that adapt to each student's unique needs and pace.
                  </p>
                </div>
              </div>
            </div>

            <div className="bg-gray-800/50 backdrop-blur-sm rounded-lg p-8">
              <div className="flex items-start gap-6">
                <Cpu className="w-12 h-12 text-purple-400 flex-shrink-0" />
                <div>
                  <h2 className="text-2xl font-bold text-white mb-4">Our Technology</h2>
                  <p className="text-gray-300 leading-relaxed mb-6">
                    At the heart of our platform lies advanced AI technology that understands 
                    each learner's style, pace, and preferences. Our system continuously adapts 
                    to provide the most effective learning path for every individual.
                  </p>
                  <ul className="space-y-4">
                    <li className="flex items-center gap-3">
                      <span className="w-2 h-2 bg-purple-400 rounded-full"></span>
                      <span className="text-gray-300">Adaptive Learning Algorithms</span>
                    </li>
                    <li className="flex items-center gap-3">
                      <span className="w-2 h-2 bg-purple-400 rounded-full"></span>
                      <span className="text-gray-300">Real-time Progress Tracking</span>
                    </li>
                    <li className="flex items-center gap-3">
                      <span className="w-2 h-2 bg-purple-400 rounded-full"></span>
                      <span className="text-gray-300">Personalized Content Delivery</span>
                    </li>
                    <li className="flex items-center gap-3">
                      <span className="w-2 h-2 bg-purple-400 rounded-full"></span>
                      <span className="text-gray-300">Interactive Learning Experience</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="bg-gray-800/50 backdrop-blur-sm rounded-lg p-8">
              <h2 className="text-2xl font-bold text-white mb-6">Our Impact</h2>
              <div className="grid md:grid-cols-3 gap-8">
                <div className="text-center">
                  <div className="text-4xl font-bold text-purple-400 mb-2">10K+</div>
                  <div className="text-gray-300">Active Learners</div>
                </div>
                <div className="text-center">
                  <div className="text-4xl font-bold text-purple-400 mb-2">95%</div>
                  <div className="text-gray-300">Completion Rate</div>
                </div>
                <div className="text-center">
                  <div className="text-4xl font-bold text-purple-400 mb-2">50+</div>
                  <div className="text-gray-300">Tech Courses</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}