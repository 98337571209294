import { GraduationCap, Terminal } from 'lucide-react';

export default function AiTutor() {
  return (
    <section className="py-24 bg-gray-900 relative overflow-hidden" id="ai-tutor">
      <div className="absolute inset-0 bg-gradient-to-br from-cyan-900/20 to-purple-900/20"></div>
      
      <div className="container mx-auto px-4">
        <div className="text-center max-w-3xl mx-auto mb-16 relative z-10">
          <div className="flex items-center justify-center gap-2 mb-4">
            <GraduationCap className="text-cyan-400 w-6 h-6" />
            <span className="text-cyan-400">AI Tutor</span>
          </div>
          <h2 className="text-4xl md:text-5xl font-bold text-white mb-6">
            AI Tutor: Personalized Learning Tailored to You
          </h2>
          <p className="text-gray-300 text-lg leading-relaxed">
            Experience the world's first AI-driven personalized hands-on tutor. The AI Tutor crafts a custom learning
            sequence for any tech topic you want to master. Each task is validated for accuracy, ensuring you're
            always on the right path. Need help with a prerequisite? The AI Tutor has you covered.
          </p>
        </div>

        <div className="relative max-w-5xl mx-auto">
          {/* Terminal Window */}
          <div className="relative z-10 bg-gray-800 rounded-lg shadow-2xl overflow-hidden">
            {/* Terminal Header */}
            <div className="bg-gray-900 px-4 py-2 flex items-center gap-2">
              <div className="flex gap-2">
                <div className="w-3 h-3 rounded-full bg-red-500"></div>
                <div className="w-3 h-3 rounded-full bg-yellow-500"></div>
                <div className="w-3 h-3 rounded-full bg-green-500"></div>
              </div>
              <div className="flex items-center gap-2 ml-4">
                <Terminal className="w-4 h-4 text-gray-400" />
                <span className="text-sm text-gray-400">AI Tutor Terminal</span>
              </div>
            </div>
            
            {/* Terminal Content */}
            <div className="p-6">
              <img
                src="https://images.unsplash.com/photo-1629654297299-c8506221ca97?auto=format&fit=crop&q=80"
                alt="AI Tutor Interface"
                className="rounded-lg w-full shadow-lg"
              />
            </div>
          </div>

          {/* Decorative Elements */}
          <div className="absolute -top-10 -right-10 w-60 h-60 bg-cyan-500/20 rounded-full blur-3xl"></div>
          <div className="absolute -bottom-10 -left-10 w-60 h-60 bg-purple-500/20 rounded-full blur-3xl"></div>
        </div>
      </div>
    </section>
  );
}