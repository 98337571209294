import { Zap, Target, Clock, Brain } from 'lucide-react';
import { Link } from 'react-router-dom';
import WaveAnimation from '../components/WaveAnimation';

const benefits = [
  {
    icon: Brain,
    title: 'Personalized Learning',
    description: 'AI-driven content tailored to your learning style and pace',
    link: '/benefits/personalized-learning'
  },
  {
    icon: Target,
    title: 'Targeted Progress',
    description: 'Focus on areas that need improvement with smart recommendations',
    link: '/benefits/targeted-progress'
  },
  {
    icon: Clock,
    title: 'Learn Faster',
    description: 'Optimize your learning journey with efficient study paths',
    link: '/benefits/learn-faster'
  },
  {
    icon: Zap,
    title: 'Instant Feedback',
    description: 'Real-time assessment and adaptive learning paths',
    link: '/benefits/instant-feedback'
  }
];

export default function BenefitsPage() {
  return (
    <div className="min-h-screen bg-gray-900 pt-24 relative overflow-hidden">
      <WaveAnimation />
      
      <div className="container mx-auto px-4 py-12 relative z-10">
        <div className="text-center mb-16">
          <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
            Platform Benefits
          </h1>
          <p className="text-xl text-gray-300 max-w-3xl mx-auto">
            Discover how our AI-powered platform transforms your learning experience
            with personalized guidance and real-time feedback.
          </p>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
          {benefits.map((benefit, index) => (
            <Link
              key={index}
              to={benefit.link}
              className="p-6 bg-gray-800/50 backdrop-blur-sm rounded-lg hover:bg-gray-750 transition-all group"
            >
              <benefit.icon className="w-12 h-12 text-purple-400 mb-4 group-hover:scale-110 transition-transform" />
              <h3 className="text-xl font-semibold text-white mb-2">
                {benefit.title}
              </h3>
              <p className="text-gray-300 mb-4">{benefit.description}</p>
              <span className="text-purple-400 text-sm group-hover:underline">
                Learn more →
              </span>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}