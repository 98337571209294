import { ChevronDown } from 'lucide-react';

export default function ScrollIndicator() {
  const scrollToContent = () => {
    window.scrollTo({
      top: window.innerHeight,
      behavior: 'smooth'
    });
  };

  return (
    <button 
      onClick={scrollToContent}
      className="absolute bottom-8 left-1/2 transform -translate-x-1/2 flex flex-col items-center text-white/60 hover:text-white/90 transition-colors"
    >
      <span className="text-sm mb-2">Scroll Down</span>
      <ChevronDown className="w-6 h-6 animate-bounce" />
    </button>
  );
}