import { Languages, Globe2 } from 'lucide-react';
import ComingSoonPopup from './common/ComingSoonPopup';
import { useComingSoon } from '../hooks/useComingSoon';

export default function MultipleLanguages() {
  const { isPopupOpen, showComingSoon, hideComingSoon } = useComingSoon();

  return (
    <section className="py-24 bg-gray-900 relative overflow-hidden" id="multiple-languages">
      <div className="container mx-auto px-4">
        <div className="grid md:grid-cols-2 gap-12 items-center">
          <div className="relative">
            <div className="relative z-10 bg-gradient-to-br from-purple-900/20 to-blue-600/20 rounded-lg p-2 shadow-2xl">
              {/* Terminal Window */}
              <div className="bg-gray-900 rounded-lg overflow-hidden">
                {/* Terminal Header */}
                <div className="bg-gray-800 px-4 py-2 flex items-center gap-2">
                  <div className="flex gap-2">
                    <div className="w-3 h-3 rounded-full bg-red-500"></div>
                    <div className="w-3 h-3 rounded-full bg-yellow-500"></div>
                    <div className="w-3 h-3 rounded-full bg-green-500"></div>
                  </div>
                </div>
                
                {/* Terminal Content */}
                <div className="p-6">
                  <div className="flex items-center gap-4 mb-4">
                    <Globe2 className="text-blue-400 w-8 h-8" />
                    <div className="text-blue-400 font-mono">Tech Learn Academy</div>
                  </div>
                  <div className="space-y-4">
                    <div className="bg-gray-800 p-3 rounded">
                      <p className="text-green-400 font-mono">Hello! How can I help now?</p>
                    </div>
                    <div className="bg-gray-800 p-3 rounded">
                      <p className="text-purple-400 font-mono">Hello, Ami Kivabe apnake shohojogita korte pari</p>
                    </div>
                    <div className="bg-gray-800 p-3 rounded">
                      <p className="text-pink-400 font-mono">হ্যালো! আমি কীভাবে আপনাকে সাহায্য করতে পারি?</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            {/* Decorative elements */}
            <div className="absolute -top-10 -right-10 w-40 h-40 bg-blue-600/20 rounded-full blur-3xl"></div>
            <div className="absolute -bottom-10 -left-10 w-40 h-40 bg-purple-600/20 rounded-full blur-3xl"></div>
          </div>

          <div className="relative z-10">
            <div className="flex items-center gap-2 mb-4">
              <Languages className="text-blue-400 w-6 h-6" />
              <span className="text-blue-400">Multi-lingual</span>
            </div>
            <h2 className="text-4xl md:text-5xl font-bold text-white mb-6">
              Multiple Languages
            </h2>
            <p className="text-gray-300 text-lg leading-relaxed mb-8">
              Our AI Assistant just broke another barrier in education – now supporting multiple languages including Bangla! 
              Chat with it in any language, and it'll respond back in the same language. This is the next 
              level of learning, making education truly accessible globally.
            </p>
            <button 
              onClick={showComingSoon}
              className="inline-flex items-center bg-blue-600 hover:bg-blue-700 text-white font-semibold px-6 py-3 rounded-lg transition-all"
            >
              <Globe2 className="w-5 h-5 mr-2" />
              Try Different Languages
            </button>
          </div>
        </div>
      </div>

      <ComingSoonPopup 
        isOpen={isPopupOpen}
        onClose={hideComingSoon}
      />
    </section>
  );
}