import { LogIn, User, BookOpen } from 'lucide-react';
import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Logo from './Logo';

export default function Navigation() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState<any>(null);
  const location = useLocation();

  // Update auth state whenever location changes or component mounts
  useEffect(() => {
    const checkAuth = () => {
      const token = localStorage.getItem('auth_token');
      const storedUser = localStorage.getItem('user');
      setIsLoggedIn(!!token);
      setUser(storedUser ? JSON.parse(storedUser) : null);
    };

    // Check initial auth state
    checkAuth();

    // Add storage event listener
    const handleStorageChange = (e: StorageEvent) => {
      if (e.key === 'auth_token' || e.key === 'user') {
        checkAuth();
      }
    };

    window.addEventListener('storage', handleStorageChange);

    // Cleanup
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [location]);

  return (
    <nav className="fixed w-full z-50">
      <div className="container mx-auto px-4 py-3">
        <div className="bg-white/10 backdrop-blur-md rounded-full px-6 py-3 flex justify-between items-center shadow-lg">
          <Link 
            to="/" 
            className="hover:opacity-90 transition-opacity relative group"
          >
            <Logo />
            <div className="absolute inset-0 bg-white/5 rounded-full opacity-0 group-hover:opacity-100 transition-opacity"></div>
          </Link>

          <div className="flex items-center gap-4">
            <Link
              to="/courses"
              className="relative inline-flex items-center px-6 py-2.5 rounded-full bg-white/15 text-white font-semibold 
                transition-all duration-300 ease-in-out hover:bg-white/20 hover:scale-105 hover:shadow-lg 
                hover:shadow-white/10 active:scale-95 group overflow-hidden"
            >
              <div className="absolute inset-0 bg-gradient-to-r from-purple-500/20 to-pink-500/20 rounded-full opacity-0 group-hover:opacity-100 transition-opacity"></div>
              <BookOpen className="w-4 h-4 mr-2 relative z-10" />
              <span className="relative z-10">Courses</span>
            </Link>

            {isLoggedIn ? (
              <Link
                to="/dashboard"
                className="relative inline-flex items-center px-6 py-2.5 rounded-full bg-white/15 text-white font-semibold 
                  transition-all duration-300 ease-in-out hover:bg-white/20 hover:scale-105 hover:shadow-lg 
                  hover:shadow-white/10 active:scale-95 group overflow-hidden"
              >
                <div className="absolute inset-0 bg-gradient-to-r from-purple-500/20 to-pink-500/20 rounded-full opacity-0 group-hover:opacity-100 transition-opacity"></div>
                <User className="w-4 h-4 mr-2 relative z-10 group-hover:animate-pulse" />
                <span className="relative z-10">{user?.name || 'Profile'}</span>
              </Link>
            ) : (
              <Link
                to="/login"
                className="relative inline-flex items-center px-6 py-2.5 rounded-full bg-white/15 text-white font-semibold 
                  transition-all duration-300 ease-in-out hover:bg-white/20 hover:scale-105 hover:shadow-lg 
                  hover:shadow-white/10 active:scale-95 group overflow-hidden"
              >
                <div className="absolute inset-0 bg-gradient-to-r from-purple-500/20 to-pink-500/20 rounded-full opacity-0 group-hover:opacity-100 transition-opacity"></div>
                <LogIn className="w-4 h-4 mr-2 relative z-10" />
                <span className="relative z-10">Login</span>
              </Link>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
}